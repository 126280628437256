
import {computed, defineComponent, onMounted, ref,} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import {ErrorMessage, Field, Form} from "vee-validate";


export default defineComponent({
  name: "bank-account-update",
  components: {
    Field,
    Form,
    ErrorMessage,
  },


  props: {
    itemID: String,
  },

  watch: {
    itemID() {
      const payloadit = {
        "ID": this.itemID
      };

      store.dispatch(Actions.BANKS_DETAILS, payloadit);
    },
  },


  setup(props) {
    store.dispatch(Actions.BANKS_DETAILS, {'ID': props.itemID});


    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);

    const dataBankListDetails = computed(() => {
      let dataComputed = store.getters.banksDetails;
      if (dataComputed !== undefined) {
        setMyDetails(dataComputed);
      }
      return dataComputed;
    })

    function setMyDetails(data) {
      myDetails.value = data;
    }

    const myDetails = ref({});

    const validationSchema = Yup.object().shape({
      Title: Yup.string().required("Doldurulması zorunlu"),
      ShortHand: Yup.string().required("Doldurulması zorunlu"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }


      const payload1 = {
        ID: props.itemID,
        Title: (myDetails.value as any).Title,
        ShortHand: (myDetails.value as any).ShortHand,
      }


      store.dispatch(Actions.BANKS_EDIT, payload1)
          .then(() => {
            store.dispatch(Actions.BANKS_LIST, {});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newRoomModalRef.value);
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getBanksErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });
    }

    return {
      submitButtonRef,
      newRoomModalRef,
      validationSchema,
      submit,
      myDetails,
      dataBankListDetails,

    };
  },
});
